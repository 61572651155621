
<template>
  <div class="downlinesearch-card">
    <form @submit.prevent="searchDownline">
      <div class="form-group row">
        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
          <label>First Name</label>
          <input
            type="text"
            class="downlinesearch-field"
            placeholder="Enter First Name"
            v-model="filters.agent_fname"
          />
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
          <label>Last Name</label>
          <input
            type="text"
            class="downlinesearch-field"
            placeholder="Enter Last Name"
            v-model="filters.agent_lname"
          />
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
          <label>Rep Phone</label>
          <input
            type="text"
            class="downlinesearch-field"
            placeholder="Enter Rep Phone"
            v-model="filters.agent_phone"
          />
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
          <label>Rep Email</label>
          <input
            type="email"
            class="downlinesearch-field"
            placeholder="Rep Email"
            v-model="filters.agent_email"
          />
        </div>
      </div>

      <div class="form-group row">
        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
          <label>Rep Code</label>
          <input
            type="text"
            class="downlinesearch-field"
            placeholder="Enter Rep Code"
            v-model="filters.agent_code"
          />
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
          <label>Upline Rep Code</label>
          <input
            type="text"
            class="downlinesearch-field"
            placeholder="Enter Upline Rep Code"
            v-model="filters.upline_code"
          />
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
          <label>Upline Rep Name</label>
          <input
            type="text"
            class="downlinesearch-field"
            placeholder="Enter Upline Rep Name"
            v-model="filters.upline_name"
          />
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
          <label>State</label>
          <multiselect
            name="state"
            v-model="filters.agent_state"
            placeholder="Select State"
            :options="states.map((type) => type.value)"
            :custom-label="(opt) => states.find((x) => x.value == opt).text"
          />
        </div>
      </div>

      <div class="form-group row">
        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
          <label>Group</label>
          <multiselect
            name="group"
            v-model="filters.group_id"
            placeholder="Select Group"
            :options="groups.map((type) => type.group_id)"
            :custom-label="
              (opt) => groups.find((x) => x.group_id == opt).group_name
            "
            :options-limit="100"
            :internal-search="false"
            :local-search="false"
            @search-change="searchHandler"
            @open="getGroups"
          />
        </div>
      </div>

      <div class="row justify-content-md-end">
        <div class="col-xxl-2 col-xl-3">
          <button type="submit" class="univ-btn">Search</button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
export default {
  name: "DownlineRepListFilter",
  props: {
    filters: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    states: Helper.getStates(),
    groups: [],
    status: [
      { text: "Active", value: "A" },
      { text: "Pending", value: "P" },
      { text: "Disabled", value: "D" },
      { text: "Suspended", value: "S" },
    ],
  }),
  methods: {
    searchHandler(query) {
      if (query.length > 2) {
        this.getGroups(query);
      }
    },
    getGroups(query = null) {
      let url;
      const baseUrl = `v2/get-rep-group-list?size=100`;
      if (query) {
        url = `${baseUrl}&group_name=${query}`;
      } else {
        url = baseUrl;
      }
      Api.get(url)
        .then((res) => {
          this.groups = res.data.data;
        })
        .catch(() => {
          console.log("Failed to fetch groups");
        });
    },
    searchDownline() {
      this.$emit("onSearch", this.filters);
    },
  },
  created() {
    this.getGroups();
  },
};
</script>